$(function () {

    var demoSystem = window.demoSystem || {};

    var personalUser = demoSystem.personalUser = function () {};

    personalUser.prototype = {
        realName: null,
        userType: null,
        setData: {},
        init: function () {
            this.bindEvent();
        },
        bindEvent:function(){
            $('#tabsTitle > li').on('click',function(){
                var ind = $(this).index();
                $(this).addClass('active').siblings().removeClass('active');
                $('#tabsContent > li').eq(ind).addClass('active').siblings().removeClass('active');
            })
            $('.card-container > img').on('click',function(){
                var ind = $(this).index();
                $(this).addClass('active').siblings().removeClass('active');
                $('.slices-container').eq(ind).addClass('active').siblings().removeClass('active');
            })
        },
        getStatus: function () {
            var timeCount = 0,
                textZone = $('#loginMessages > span');

            setInterval(function () {
                textZone.text(timeCount++);
            }, 1000)

        }

    }


    var st = new demoSystem.personalUser();

    st.init();
})